import { sign } from "@/util/sign.js"
const common = {
  deepClone(origin, target) {
    var _this = this;
    var target = target || {};
    for (var prop in target) {
      if (target.hasOwnProperty(prop)) {
        if (target[prop] !== null && typeof target[prop] === 'object') {
          origin[prop] = Object.prototype.toString.call(target[prop]) === '[object Array]' ? [] : {};
          _this.deepClone(origin[prop], target[prop]);
        } else {
          origin[prop] = target[prop];
        }
      }
    }
  }
}

// 正则校验规则
// 大于0小于100的正整数  [ /^(?:0|[1-9][0-9]?|100)(\.[0-9]{0,2})?$/ ]
export const REG_EXP = {
  mobilePhone: /^1\d{10}/,
  email: /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
  money: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
  identityCard: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
  password: /^(?![0-9]+$)(?![a-zA-Z]+$)(?![~!@#$%^&*()_+/*]+$)[0-9A-Za-z~!@#$%^&*()_+/*]{6,16}$/,
  loginName: /^[a-zA-Z0-9]{6,20}$/,
  setup: /^(0|[1-9]\d*)(\.\d{1,1})?$/, // 只能輸入数字，最多一位小数点

};

// 导出
export let exportData = (obj, url)=>{
  obj.timestamp = new Date().getTime();
  obj.token = localStorage.getItem('token');
  let str = '';
  Object.keys(obj).forEach((item)=>{
    str += `&${item}=${obj[item]}`
  })
  let data = sign(obj);
  let _baerUrl = process.env.VUE_APP_BASE_API
  // console.log(`${_baerUrl}${url}?sign=${data}${str}`,3)
  window.open(`${_baerUrl}${url}?sign=${data}${str}`)
}
 

export default common;